export default function useLocalStorage() {
  const setLocalStorage = (key: string, data: string) => {
    window.localStorage.setItem(key, data);
  };
  const getLocalStorage = (key: string) => window.localStorage.getItem(key);
  const deleteLocalStorage = (name: string) => {
    window.localStorage.removeItem(name);
  };
  return {
    setLocalStorage, getLocalStorage, deleteLocalStorage,
  };
}
