export default {
    key: 'en-US',
    strings: {  
        partner: {
            addPartner: 'Add Partner',
            form: {
                tenantId: 'Tenant ID:',
                addAffinityPartner: 'Add Affinity Partner',
                affinityPartners: 'Affinity Partners',
                affinityPartner: 'Affinity Partner',
                enterTenantId: 'Enter Tenant ID',
                partnerName: 'Partner Name',
                enterPartnerName: 'Enter Partner Name',
                salesforceId: 'Salesforce ID',
                enterSalesforceId: 'Enter Salesforce ID',
                tenantLimit: 'Tenant Limit',
                enterTenantLimit: 'Enter Tenant Limit',
                uiConfig: 'UI Configuration',
                addTenant: 'Add Tenant',
                tenantName: 'Tenant Display Name',
                enterTenantName: 'Enter Tenant Display Name',
                enterSalesforceIdDigit: 'Enter 15 or 18 Salesforce ID',
                tenantPermission: 'Tenant Permission',
                tenantSalesforceId: ' SALESFORCE ID:',
                tenantDefaultPermission: 'Tenant Default Permission',
                enterCharId: 'Please enter 15 or 18 char ID',
                confirmAddPartner: 'Confirmation Add Partner',
                confirmation: '<b>Do you want to add this Affinity Partner?</b><br>Please confirm that you want to add <b>{displayName}</b>',
                buttons: {
                    resetForm: 'Reset Form',
                    add: 'ADD',
                    edit: 'EDIT',
                    save: 'SAVE',
                    cancel: 'CANCEL',
                    update: 'Update',
                    submit: 'Submit',
                    saveModal: 'Save',
                    cancelModal: 'Cancel'
                }
            }
        },
        configurations:{
            fieldId:'Field ID',
            label:'Label',
            sortable: 'Sortable',
            sortName: 'Sort Name',
            existingConfiguration: 'Existing Configuration',
            newConfiguration:'New Configuration',
            newProductField: 'New Product Field',
            column: 'Column',
            fieldSelection: 'Field Selection',
            status:'Status',
            invalid:'Invalid Config Type',
            tenant: 'TENANT',
            configuration: 'CONFIGURATION',
            copyExisting:'Copy Existing',
            existingConfiguration:'Existing Configuration',
            newConfiguration:'New Configuration',
            exitConfiguration: 'Are you sure you want to exit these configurations without saving?',
            buttons:{
                addField: ' Add Field',
                cancel: 'Cancel',
                update: 'Update',
                useLegacyConfig:'Use Legacy Config',
                effectiveConfig: 'Effective Config',
                save: 'Save',
                close:'Close',
                useNewConfig:'Use New Config',
            }
        },
        connections:{
            dataLakeAutomation: 'DataLake Automation',
            test: 'Test',
            result: 'Result',
            delete:'You are about to delete the {TypeS}',
            connector:'connector for',
            confirm:'Are you sure?',
            buttons:{
                delete: 'Delete'
            }
        },
        metrics: {
            assetCount: 'Asset Count',
            lastInsight: 'Last Insight',
            insightMetadata: 'Insight Metadata',
            modelName: 'Model Name',
            creationDate: 'Creation Date',
            insightsByType: 'Insights By Type',
            count:'Count',
            insightsAccepted: 'Insights Accepted',
            insightsDisregarded: 'Insights Disregarded',
            casesCreated: 'Cases Created',
            casesClosed: 'Cases Closed',
            failedFilesInBronze:'Failed Files In Bronze',
            state: 'State',
            s3Key:'S3 Key',
            failedFilesInSilver: 'Failed Files In Silver',
            failedFilesInGold: 'Failed Files In Gold'
        },
        quicksight: {
            addNewDashboard: 'Add New Dashboard',
            title: 'Title',
            enterTitle:'Enter Title',
            dashboardId: 'Dashboard Id',
            enterDashboardId:'Enter Dashboard Id',
            saveChanges: 'Save Changes',
            confirmSaveChanges: 'Are you sure you want to save the changes?',
            deleteDashboard: 'Delete Dashboard',
            confirmDelete:' Are you sure you want to delete the "',
            dashboard: '" dashboard?',
            analyticsDashboards: 'Analytics Dashboards',
            buttons:{
                cancel: 'Cancel',
                save: 'Save',
                confirmSave: 'Confirm Save',
                confirmDelete: 'Confirm Delete',
                delete: 'Delete',
                edit: 'Edit',
                addNewItem: 'Add New Item',
            }
        },
        shared:{
            tenantIdRequirements: 'Tenant ID Requirements',
            first: 'First',
            next: 'Next',
            prev: 'Prev',
            last: 'Last',
            management: 'Management',
            buttons:{
                cancel: 'Cancel',
                continue: 'Continue',
            }
        },
        taxonomy:{
            download: 'You can download a',
            template: 'CSV taxonomy template',
            started: 'to get you started',
            taxonomyList: 'Taxonomy List',
            name: 'Name',
            version: 'Version',
            uploadDate:'Upload Date',
            action: 'Action',
            inUse: 'In Use',
            file: 'File',
            taxonomyName: 'Taxonomy Name',
            confirmationText: 'Are you sure you want to use {taxonomyName}?',
            buttons:{
                use: 'Use',
                upload: 'Upload',
                close: 'close',
            }
        },
        users:{
            download: 'DOWNLOAD CSV',
            loginHistory: 'Login History',
            dateTime: 'Date Time (CST)',
            name: 'Name',
            emailAddress: 'Email Address',
            selected: 'Selected',
            editUser: 'Edit user',
            newUser: 'New User',
            userName: 'User Name',
            firstName: 'First Name',
            lastName: 'Last Name',
            emailAddress: 'Email Address',
            role: 'Role',
            confirmationTextDelete: 'Are you sure you want to delete user {username}?',
            confirmationTextImpersonate:'Are you sure you want to impersonate user {username}?',
            buttons:{
                resetPassword: 'Reset Password',
                cancel: 'Cancel',
                save: 'Save',
                addUser: 'Add User',
                close: 'close'
            }
        },
        administration:{
            fieldId: 'Field ID',
            label: 'Label',
            sortable: 'Sortable',
            sortName: 'Sort Name',
            fixedFields: 'Fixed Fields',
            delete: 'Delete',
            defaultFields: 'Default Fields',
            global:'GLOBAL',
            configuration: 'CONFIGURATION',
            newProductField: 'New Product Field',
            confirmation:'confirmation',
            confirmationText:'Are you sure you want to exit global configuration without saving?',
            addField: 'add field',
            buttons:{
                addField: ' Add Field',
                delete: 'Delete',
                save: 'Save',
                cancel: 'Cancel',
            }
        },
        home:{
            tenantInformation:'Tenant Information',
            workOrder: 'Work Order Analytics Enabled',
            acceptInsight: 'Accept Insight Disabled',
            casesFeature: 'Cases Feature Enabled',
            fleetInsightDashboard :'Fleet Insight Assessment Dashboard Enabled',
            nearTimeFaults: 'Near-time Faults Enabled',
            selectTenant: 'Select a Tenant to view its information',
            disableExternalUser: 'Customer Access',

        },
        'uptake-component-library': {
            pagination: {
              viewing: 'Viewing {current} of {total} Results',
              first: 'First',
              last: 'Last',
            },
        },
    },
};