import axios, {
  AxiosError, type AxiosInstance, type AxiosRequestConfig, type AxiosResponse,
} from 'axios';
import useAuthStore from '@/store/auth';
import Keycloak from 'keycloak-js';
import keycloakClientOptions from '@/api/auth/options';
import useLocalStorage  from '@/utils/localStorageUtils';

class Client {
  public instance: AxiosInstance;
  public baseUrl: string;
  public isRefreshing = false;
  public keycloak: Keycloak;

  public constructor(baseUrl = '') {
    this.instance = axios.create();
    this.baseUrl = baseUrl;
    this.keycloak = new Keycloak(keycloakClientOptions);
    this.initializeResponseInterceptor();
    this.initializeRequestInterceptor();
  }

  private initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      this.handleResponse,
      this.handleError,
    );
  };

  private initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(
      this.handleRequest,
      this.handleError,
    );
  };

  private handleRequest = async (config: AxiosRequestConfig) => {
    const { token } = useAuthStore();
    try {
      config.baseURL = this.baseUrl;
      if(config.headers) {
        config.headers.Accept = 'application/json, text/plain';
        config.headers.Authorization = token;
      }
      config.withCredentials = false;
      return config;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  public handleResponse = (response: AxiosResponse) => (response);

  public handleError = (error: AxiosError) => {
    this.handleBadRequest(error);    
    return Promise.reject(error);
  };

  public handleBadRequest = (error: AxiosError) => {
    if (error.response !== undefined && error.response.status === 400) {
      console.error('non auth error');
    }
    return Promise.reject(error);
  };

  public handleImpersonation = async (token: string, userId: string, tenantId:string) => {
   await axios.post(`${keycloakClientOptions.url}admin/realms/${tenantId}/users/${userId}/impersonation`, {}, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
      withCredentials: true,
    })
    .then(() => {
      const envURL =  useLocalStorage().getLocalStorage('redirectUrl');
      const url = `${envURL}?realm=${tenantId}`;
      window.open(url, '_blank');
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };
}
export default Client;

export interface APIResponse<T> {
  data: T;
}