import Keycloak from 'keycloak-js';
import useAuthStore from '@/store/auth';
import keycloakClientOptions from './options';

export const keycloak = new Keycloak(keycloakClientOptions);

export function buildBearerToken() {
  const bearerPrefix = 'Bearer ';
  const { token } = keycloak;
  return bearerPrefix + token;
}

keycloak.onTokenExpired = () => {
  if (keycloak.refreshToken) {
    keycloak
      .updateToken(100)
      .then((success) => {
        const authStore = useAuthStore();
        if (success) {
          authStore.token = buildBearerToken();
        } else {
          keycloak.logout();
        }
      })
      .catch((error) => {
        console.error(error);
        keycloak.logout();
      });
  } else {
    keycloak.logout();
  }
};

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $keycloak: typeof keycloak;
  }
}
