import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from '@/App.vue';
import router from '@/router';
import { buildBearerToken, keycloak } from '@/api/auth';
import apiClient from '@/api/plugin';
import useAuthStore from '@/store/auth';
import clickOutside from './directives/click-outside';
import i18n from '@/locales';
import useLocalStorage  from '@/utils/localStorageUtils';
import 'cirrus-ui';
import { installSharedComponents } from '@uptake/uptake-component-library';
import '@uptake/uptake-component-library/dist/style.css'

const pinia = createPinia();
const app = createApp(App).use(pinia).use(i18n);
app.directive('click-outside', clickOutside);
useLocalStorage().setLocalStorage('redirectUrl', import.meta.env.VITE_ENV_URL)

keycloak
  .init({ onLoad: 'login-required', pkceMethod: 'S256', enableLogging: true })
  .then((auth) => {
    const authStore = useAuthStore();
    if (!auth) {
      authStore.isAuthenticated = false;
      authStore.role = '';
      authStore.token = '';
    } else {
      authStore.isAuthenticated = true;
      authStore.role = 'admin';
      authStore.token = buildBearerToken();
      app.use(router);

      app.use(apiClient);
      app.use(installSharedComponents);

      app.mount('#app');
      app.config.globalProperties.$keycloak = keycloak;
    }
  })
  .catch((error) => {
    console.log(keycloak);
    new Error(error.message);
  });




declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $style: Record<string, string>;
  }
}

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $keycloak: typeof keycloak;
  }
}
