import Client from '../client';

export default class TaxonomyApi extends Client {
  constructor(baseUrl = import.meta.env.VITE_TAXONOMY_URL) {
    super(baseUrl);
    this.baseUrl = import.meta.env.VITE_TAXONOMY_URL;
  }

  public async uploadAsset(asset: FormData, tenantId:string, taxonomyName:string) {
    return this.instance.post(
      `/v1/${tenantId}/${taxonomyName}`,
      asset,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
        },
      },
    );
  }

  public async getTaxonomies(tenantId: string) {
    return this.instance.get(`/v1/taxonomyhistory/${tenantId}`);
  }

  public async getTaxonomyTree(tenantId:string, taxonomyName: string, taxonomyVersion: string) {
    return this.instance.get(`/v1/${tenantId}/${taxonomyName}/${taxonomyVersion}/tree`);
  }
}
