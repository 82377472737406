import Client from '../client';

export default class MetricsApi extends Client {
  constructor(baseUrl = import.meta.env.VITE_PLATFORM_METRICS_URL) {
    super(baseUrl);
    this.baseUrl = import.meta.env.VITE_PLATFORM_METRICS_URL;
  }

  public async getAssetCount(tenantId: string) {
    return this.instance.get(`/asset/count/${tenantId}`
    );
  }
  
  public async getLastInsight(tenantId: string) {
    return this.instance.get(`/asset/last_insight/${tenantId}`
    );
  }

  public async getInsightsByType(tenantId: string) {
    return this.instance.get(`/asset/insights_by_type/${tenantId}`
    );
  }

  public async getInsightsAccepted(tenantId: string) {
    return this.instance.get(`/asset/insights_accepted/${tenantId}`
    );
  }

  public async getInsightsDisregarded(tenantId: string) {
    return this.instance.get(`/asset/insights_disregarded/${tenantId}`
    );
  }

  public async getCasesCreated(tenantId: string) {
    return this.instance.get(`/asset/cases_created/${tenantId}`
    );
  }

  public async getCasesClosed(tenantId: string) {
    return this.instance.get(`/asset/cases_closed/${tenantId}`
    );
  }

  public async getFailedFiles(tenantId: string, stage:string) {
    return this.instance.get(`/failed_files/${tenantId}/${stage}`
    );
  }

}
