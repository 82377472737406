<template>
  <teleport to="body">
    <transition-group
      :name="$style.fade"
      tag="div"
      :class="$style.toasts"
    >
      <div
        v-for="toast in messages()"
        :key="toast.id"
        :class="toastClass(toast)"
      >
        <div :class="$style.toasts__title">
          {{ toast.title }}
        </div>
        <div>
          <RouterLink
            v-if="toast.action"
            :to="toast.action"
            :class="$style.toasts__action"
          >
            {{ toast.message }}
          </RouterLink>
          <template v-else>
            {{ toast.message }}
          </template>
        </div>
      </div>
    </transition-group>
  </teleport>
</template>
  
  <script lang="ts">
  import { defineComponent } from 'vue';
  import useSystemStore, { MessageType } from '@/store/system';
  
  export default defineComponent({
    name: 'ToastMessage',
    setup() {
      const systemStore = useSystemStore();
      const messages = () => useSystemStore().getSystem;
      systemStore.$subscribe(() => messages(), { detached: true });
      return {
        messages,
      };
    },
    methods: {
      toastClass(toast) {
        let style;
        switch (toast.type) {
          case MessageType.Error:
            style = 'toasts__message-error';
            break;
          case MessageType.Warning:
            style = 'toasts__message-warning';
            break;
          default:
            style = 'toasts__message-success';
            break;
        }
        return ['toasts__message', style].map((style) => this.$style[style]);
      },
    },
  });
  </script>
  
  <style module lang="scss">
  .toasts {
    position: fixed;
    z-index: 9999;
    bottom: 20px;
    right: 20px;
    transition: height 2s ease;
  
    &__message {
      color: ds-get-color(egg-white);
      padding: 10px 15px;
      min-width: 320px;
      border-radius: $border-radius;
      margin-top: 5px;
  
      &-error {
        background-color: ds-get-color(blood-red);
      }
  
      &-warning {
        background-color: ds-get-color(clementine-orange);
      }
  
      &-success {
        background-color: ds-get-color(pine-green);
      }
    }
    &__title {
      display: block;
      font-weight: bold;
    }
  
    &__action {
      color: ds-get-color(egg-white);
      margin-top: 25px;
    }
  }
  .fade {
    &:global(-enter-active),
    &:global(-leave-active) {
      transition: opacity 0.5s;
    }
    &:global(-enter-from),
    &:global(-leave-to) {
      opacity: 0;
    }
  }
  </style>