import { defineStore } from 'pinia';

interface AuthState {
  isAuthenticated: boolean,
  signedTerms: boolean,
  token: string,
  role: string,
  tenant: string,
  impersonationToken: string
}

export default defineStore('auth', {
  state: (): AuthState => ({
    isAuthenticated: false,
    signedTerms: false,
    token: '',
    role: '',
    tenant: '',
    impersonationToken: undefined
  }),
  actions: {
    setImpersonationToken(token) {
      this.impersonationToken = token;
    },
    clearImpersonationToken() {
      this.impersonationToken = null;
    },
  },
  getters: {
    getParsedToken: (state) => state.token !== undefined ? JSON.parse(atob(state.token.split('.')[1])) : '{}'
  },
});
