export interface TenantPermissionSelectItem {
  label: string;
  value: string;
}

export const tenantPermissionsArray: Array<TenantPermissionSelectItem> = [
  { label: 'ALL', value: 'ALL' },
  { label: 'NONE', value: 'NONE' },
];

export enum KeycloakEventType {
  LOGIN = 'LOGIN',
}
