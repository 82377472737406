import { computed } from 'vue'
import useTenantStore from "@/store/tenant";
import { CoralogixRum } from '@coralogix/browser';
import useAuthStore from '@/store/auth';

const coralogix_enabled = import.meta.env.VITE_CORALOGIX_ENABLED;
const coralogix_api_key = import.meta.env.VITE_CORALOGIX_API_KEY;
const coralogix_app_version = import.meta.env.VITE_CORALOGIX_APP_VERSION;
const environment = import.meta.env.VITE_ENVIRONMENT;
const service_name = import.meta.env.VITE_SERVICE_NAME;

const product = import.meta.env.VITE_PRODUCT_MODE;
const selectedTenant = computed(() => useTenantStore().selectedTenant);
  
export default function setupCoralogix() {
    let isCoralogixEnabled = false;
  
    if (coralogix_enabled) {
      console.log('coralogix_enabled is defined', coralogix_enabled);
      isCoralogixEnabled = JSON.parse(coralogix_enabled);
    }
  
    if (isCoralogixEnabled) {
      console.log('Coralogix is enabled and product is commercial');
      console.log('parsedToken', useAuthStore().getParsedToken);
      const parsedJwtToken = useAuthStore().getParsedToken;
      const user_name = parsedJwtToken.preferred_username;
      const user_id = parsedJwtToken.sub;
      CoralogixRum.init({
        debug: true,
        public_key: coralogix_api_key,
        application: service_name + '-' + environment,
        version: coralogix_app_version,
        coralogixDomain: 'US1',
        traceParentInHeader: {
            enabled: true,
            options: {
              propagateAwsXrayTraceHeader: true,
            }
          },
          user_context: {
            user_name: user_name,
            user_id: user_id
          },
          sessionRecordingConfig: {
          enable: true,
          autoStartSessionRecording: true,
          recordConsoleEvents: true,
          sessionRecordingSampleRate: 100,
        },
      });
    } else {
      console.log('Coralogix did NOT get initialized');
    }
  }