import { type NavigationGuardNext, type RouteLocationNormalized } from 'vue-router';
import useTenantStore from '@/store/tenant';

function guard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) {
  const tenantId = useTenantStore().getSelectedTenantId;
  if ((tenantId.length === 0) && (to.name !== 'Home')) {
    return next('/home');
  }
  return next();
}

function disabledGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) {
  next('/home');
}

export { guard, disabledGuard };
