import Client from '../client';
import type { Tenant, Tsps } from '@/api/schema';

export default class TenantApi extends Client {
  constructor(baseUrl = import.meta.env.VITE_MANAGEMENT_CONFIG_URL) {
    super(baseUrl);
    this.baseUrl = baseUrl;
  }
  public async getTenants() {
    return this.instance.get<Tenant['TenantDto'][]>(`/v1/tenants`);
  }
  public async createTenant(tenantId: string, tenant: Tenant['CreateTenantRequest']) {
    return this.instance.post<Tenant['TenantDto']>(`/v1/tenants/${tenantId}`, tenant);
  }
  public async updateTenant(tenantId: string, tenantRequestBody: Tenant['UpdateTenantRequest']) {
    return this.instance.put<Tenant['TenantDto']>(`/v1/tenants/${tenantId}`, tenantRequestBody);
  }
  public async getTenant(tenantId: string) {
    return this.instance.get<Tenant['TenantDto']>(`/v1/tenants/${tenantId}`);
  }
  public async createConnectorConfig(configObj: Tenant['CreateTspRequest'], tenantId: string) {
    return this.instance.post<Tsps['CreateTspRequest']>(`/v1/tenants/${tenantId}/tsps`, configObj);
  }
  public async updateConnectorConfig(tenantId:string, configId: string, configObj: any) {
    return this.instance.patch<Tsps['UpdateTspRequest']>(`/v1/tenants/${tenantId}/tsps/${configId}`, configObj);
  }
  public async getConnectorConfigs(tenantId: string) {
    return this.instance.get<Tsps['TspDto'][]>(`/v1/tenants/${tenantId}/tsps`);
  }
  public async deleteConnectorConfig(tenantId: string, tspType: string, configId: string) {
    return this.instance.delete(`/v1/tenants/${tenantId}/tsps/${tspType}/${configId}`);
  }
  public async getLoginHistory(tenantId: string, query: string) {
    return this.instance.get<Tenant['UserEventDto'][]>(`/v1/tenants/${tenantId}/users/events${query}`);
  }
  public async getTenantUserRoles(tenantId: string){
    return this.instance.get<Tenant['RoleRepresentation']>(`/v1/tenants/${tenantId}/users/roles`);
  }
  public async getTenantUserAccessLevels(tenantId: string){
    return this.instance.get<Tenant['RoleRepresentation']>(`/v1/tenants/${tenantId}/users/accesslevels`);
  }
}
