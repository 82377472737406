export enum FieldTestStatus {
  Untested = 'untested',
  Success = 'success',
  Failure = 'failure',
}

export interface Connection {
  configurationId: string;
  database: string;
  password: string;
  server: string;
  username: string;
}

export interface FieldTestResults {
  Auth: string;
  Device: string;
  Diagnostic: string;
  Controller: string;
  FailureMode: string;
  Connection: string;
}

export interface FieldTestResult {
  fieldName: string;
  testResult: boolean;
}

export const defaultTestResults: FieldTestResults = {
  Auth: FieldTestStatus.Untested,
  Device: FieldTestStatus.Untested,
  Diagnostic: FieldTestStatus.Untested,
  Controller: FieldTestStatus.Untested,
  FailureMode: FieldTestStatus.Untested,
  Connection: FieldTestStatus.Untested,
};


export const geoTabConfig = {
  configurationId: '',
  tspType: 'GEOTAB',
  tspAttributes: {
    password: {
      label:'Password',
      isSensitive:true,
      value:''
    },
    database: {
      label:'Database',
      isSensitive:false,
      value:''
    },
    server: {
      label:'Server',
      isSensitive:false,
      value:''
    },
    username: {
      label:'UserName',
      isSensitive:false,
      value:''
    },
  },
  enabled: true,
  dataLakeAutomation: true,
};

export const calAmpConfig = {
  configurationId: '',
  tspType: 'CALAMP',
  tspAttributes: {
    username:{
      label:'UserName',
      isSensitive:false,
      value:''
    },
    app_id: {
      label:'App Id',
      isSensitive:false,
      value:''
    },
    password: {
      label:'Password',
      isSensitive:true,
      value:''
    }
  },
  enabled: true,
  dataLakeAutomation: true
};

export const samsaraConfig = {
  configurationId: '',
  tspType: 'SAMSARA',
  tspAttributes: {
    bearer_token: {
      label:'Api Bearer Token',
      isSensitive:true,
      value:''
    },
  },
  enabled: true,
  dataLakeAutomation: false
};

export const statusDisplayConfigurations = {
  [FieldTestStatus.Failure]: {
    icon: 'fa-circle-xmark',
    label: 'Failed',
    style: 'text-red-600',
  },
  [FieldTestStatus.Success]: {
    icon: 'fa-circle-check',
    label: 'Success',
    style: 'text-green-400',
  },
  [FieldTestStatus.Untested]: {
    icon: 'fa-circle-question',
    label: 'Not tested',
    style: 'text-gray-600',
  },
};
