import { defineStore } from 'pinia';
import useSystemStore, { MessageType } from '@/store/system';
import useTenantStore from '@/store/tenant';
import { API } from '@/api/plugin';
import { type Configurations } from '@/api/schema';

export interface ConfigurationState {
  globalConfiguration: any;
  tenantConfiguration: any;
  tenantFeatureConfiguration: any;
  legacyConfiguration: any;
}

export default defineStore('configuration', {
  state: (): ConfigurationState => ({
    globalConfiguration: {},
    tenantConfiguration: undefined,
    tenantFeatureConfiguration: undefined,
    legacyConfiguration: {},
  }),

  getters: {
    getLegacyConfiguration: (state) => state.legacyConfiguration,
    getGlobalConfiguration: (state) => state.globalConfiguration,
    getTenantConfiguration: (state) => state.tenantConfiguration,
  },

  actions: {
    setLegacyConfiguration(payload: Configurations['ConfigurationDto']) {
      this.legacyConfiguration = payload;
    },
    setGlobalConfiguration(payload: Configurations['ConfigurationDto']) {
      this.globalConfiguration = payload;
    },
    setTenantConfiguration(payload: Configurations['ConfigurationDto']) {
      this.tenantConfiguration = payload;
    },
    setTenantFeatureConfiguration(payload: Configurations['ConfigurationDto']) {
      this.tenantFeatureConfiguration = payload;
    },

    toggleTenantFeature(path: string, value: boolean) {
      const currentConfig = this.tenantFeatureConfiguration.config || {};
      const updatedConfig = {
        ...currentConfig,
        [path]: { enabled: value },
      };

      this.tenantFeatureConfiguration.config = updatedConfig;

      this.updateTenantFeatureConfiguration(
        this.tenantFeatureConfiguration,
        useTenantStore().getSelectedTenantId
      );
    },

    async fetchLegacyConfiguration() {
      const systemStore = useSystemStore();
      const tenantStore = useTenantStore();
      await API.Configuration.getLegacyConfiguration(tenantStore.getSelectedTenantId)
        .then((response) => {
          const configResponse = response.data;
          const organizationalFilters = configResponse.find(
            (res) => res?.componentId === 'organizational-filters'
          );
          this.setLegacyConfiguration(organizationalFilters);
        })
        .catch((error) => {
          systemStore.addMessage({
            title: 'Error fetching configurations',
            message: error as string,
            type: MessageType.Error,
          });
        });
    },
    async fetchTenantConfiguration(tenantId: string, componentId: string) {
      const systemStore = useSystemStore();
      await API.Configuration.getConfiguration(tenantId, componentId)
        .then((response) => {
          const [tenantConfigurationObject] = response.data;
          this.setTenantConfiguration(tenantConfigurationObject);
        })
        .catch((error) => {
          systemStore.addMessage({
            title: 'Error',
            message: error as string,
            type: MessageType.Error,
          });
        });
    },

    async fetchTenantFeatureConfiguration(tenantId: string, componentId: string) {
      const systemStore = useSystemStore();
      // const getAllConfigs = await API.Configuration.getAllConfiguration(tenantId);
      try {
        const response = await API.Configuration.getFeatureConfiguration(tenantId, componentId);
        const [tenantConfigurationObject] = response.data;
        type ConfigKeys =
          | 'workOrderAnalytics'
          | 'fleetInsightAssessmentDashboard'
          | 'buttonAcceptInsights'
          | 'casesFeature'
          | 'nearTimeFaults'


        const defaultConfigurations: Record<ConfigKeys, { enabled: boolean }> = {
          workOrderAnalytics: { enabled: false },
          fleetInsightAssessmentDashboard: { enabled: false },
          buttonAcceptInsights: { enabled: false },
          casesFeature: { enabled: false },
          nearTimeFaults: { enabled: false },
        };

        if (tenantConfigurationObject && tenantConfigurationObject.config) {
          const config = tenantConfigurationObject.config as Record<
            ConfigKeys,
            { enabled: boolean }
          >;
          (Object.keys(defaultConfigurations) as ConfigKeys[]).forEach((key) => {
            if (config[key] === undefined) {
              config[key] = defaultConfigurations[key];
            }
          });
          this.setTenantFeatureConfiguration(tenantConfigurationObject);
        } else {
          console.error('Tenant configuration or config is missing');
        }
      } catch (error) {
        systemStore.addMessage({
          title: 'Error',
          message: error as string,
          type: MessageType.Error,
        });
      }
    },

    async updateGlobalConfiguration() {
      const systemStore = useSystemStore();
      const configuration = {
        config: this.globalConfiguration.config,
        tenantId: this.globalConfiguration.tenantId,
        productId: this.globalConfiguration.productId,
        domainId: this.globalConfiguration.domainId,
        componentId: this.globalConfiguration.componentId,
        appId: this.globalConfiguration.appId,
      };
      await API.Configuration.updateConfiguration(
        this.globalConfiguration.configurationId,
        configuration
      )
        .then(() => {
          systemStore.addMessage({
            title: 'Success',
            message: 'Global Configuration updated successfully',
            type: MessageType.Success,
          });
        })
        .catch((error) => {
          systemStore.addMessage({
            title: 'Error',
            message: error as string,
            type: MessageType.Error,
          });
        });
    },

    async updateTenantConfiguration(payload: {
      config?: any;
      tenantId?: any;
      productId?: any;
      domainId?: any;
      componentId?: any;
      appId?: any;
      configurationId?: any;
    }) {
      const systemStore = useSystemStore();
      const { configurationId } = payload;
      const configuration = {
        config: payload.config,
        tenantId: payload.tenantId,
        productId: payload.productId,
        domainId: payload.domainId,
        componentId: payload.componentId,
        appId: payload.appId,
      };
      await API.Configuration.updateConfiguration(configurationId, configuration)
        .then(() => {
          systemStore.addMessage({
            title: 'Success',
            message: 'Tenant Configuration updated successfully',
            type: MessageType.Success,
          });
        })
        .catch((error) => {
          systemStore.addMessage({
            title: 'Error',
            message: error as string,
            type: MessageType.Error,
          });
        });
    },

    async updateTenantFeatureConfiguration(
      payload: { config?: any; configurationSources?: any },
      tenantId: string
    ) {
      const systemStore = useSystemStore();
      const configurationSource = payload.configurationSources.find(
        (source: any) => source.tenantId === tenantId
      );

      const configuration = {
        config: payload.config,
        tenantId,
      };
      await API.Configuration.updateFeatureConfiguration(
        tenantId,
        configurationSource.configurationId,
        configuration
      )
        .then(() => {
          systemStore.addMessage({
            title: 'Success',
            message: 'Tenant Configuration updated successfully',
            type: MessageType.Success,
          });
        })
        .catch((error) => {
          systemStore.addMessage({
            title: 'Error',
            message: `Failed to update Tenant Configuration: ${error}`,
            type: MessageType.Error,
          });
        });
    },

    async createTenantFeatureConfiguration(config: any, tenantId: string, payload?: any) {
      const systemStore = useSystemStore();
      const configuration = {
        productId: payload?.productId,
        domainId: payload?.domainId,
        componentId: payload?.componentId,
        appId: payload?.appId,
        tenantId: tenantId,
        inheritanceStrategy: 'NONE',
        config: { ...config },
      };

      await API.Configuration.createFeatureConfiguration(tenantId, configuration)
        .then(() => {
          systemStore.addMessage({
            title: 'Success',
            message: 'Tenant Configuration updated successfully',
            type: MessageType.Success,
          });
        })
        .catch((error) => {
          systemStore.addMessage({
            title: 'Error',
            message: error as string,
            type: MessageType.Error,
          });
        });
    },
    
    async createTenantConfiguration(payload: Configurations['ConfigurationDto']) {
      const systemStore = useSystemStore();
      const configuration = {
        config: payload?.config,
        tenantId: payload?.tenantId,
        productId: payload?.productId,
        domainId: payload?.domainId,
        componentId: payload?.componentId,
        appId: payload?.appId,
        inheritanceStrategy: 'NONE',
      };
      await API.Configuration.createConfiguration(configuration)
        .then((response) => {
          this.setTenantConfiguration(response.data);
          systemStore.addMessage({
            title: 'Success',
            message: 'Tenant Configuration created successfully',
            type: MessageType.Success,
          });
        })
        .catch((error) => {
          systemStore.addMessage({
            title: 'Error',
            message: error as string,
            type: MessageType.Error,
          });
        });
    },
    async createLegacyConfiguration(attributes: {
      taxonomy: { name: string | undefined; version: string | undefined };
    }) {
      const systemStore = useSystemStore();
      const tenantStore = useTenantStore();
      const configuration: Configurations['ConfigurationRequest'] = {
        tenantId: tenantStore.getSelectedTenantId,
        productId: 'fleet',
        domainId: 'frontend',
        appId: 'fleet-frontend',
        componentId: 'organizational-filters',
        attributes,
      };
      await API.Configuration.createLegacyConfiguration(configuration)
        .then((response) => {
          systemStore.addMessage({
            title: 'Success',
            message: 'Taxonomy Updated',
            type: MessageType.Success,
          });
          this.setLegacyConfiguration(response.data);
        })
        .catch((error) => {
          systemStore.addMessage({
            title: 'Error updating taxonomy',
            message: error as string,
            type: MessageType.Error,
          });
        });
    },
    async updateLegacyConfiguration(attributes: {
      taxonomy: { name: string | undefined; version: string | undefined };
    }) {
      const systemStore = useSystemStore();
      const updatedConfigurarion = {
        ...this.legacyConfiguration,
        attributes,
      };
      await API.Configuration.updateLegacyConfiguration(
        this.legacyConfiguration.configurationId || '',
        updatedConfigurarion
      )
        .then((response) => {
          systemStore.addMessage({
            title: 'Success',
            message: 'Taxonomy Updated',
            type: MessageType.Success,
          });
          this.setLegacyConfiguration({ ...response.data });
        })
        .catch((error) => {
          systemStore.addMessage({
            title: 'Error updating taxonomy',
            message: error as string,
            type: MessageType.Error,
          });
        });
    },
  },
});
