import { defineStore } from 'pinia';
import useSystemStore, { MessageType } from '@/store/system';
import useTenantStore from '@/store/tenant';
import { API } from '@/api/plugin';
import { type Tsps } from '@/api/schema';
import { calAmpConfig, geoTabConfig, samsaraConfig} from '@/constants/connectorConfigs';

type ConnectorConfigsState = {
  connectors: Tsps['TspDto'][];
  test: {
    success?: boolean;
  };
};

export default defineStore('connectorConfigs', {
  state: (): ConnectorConfigsState => ({
    connectors: [] as any,
    test: {
      success: false,
    },
  }),

  getters: {
    getConnectorFromTspType: (state) => {
      return (tspType: string) =>
        state.connectors.find((connector) => connector?.tspType?.toUpperCase() === tspType.toUpperCase());
    },
    getConnectorConfurationId: (state) => {
      return (tspType: string) =>
        state.connectors.find((connector) => connector?.tspType?.toUpperCase() === tspType.toUpperCase())?.configurationId;
    },
    getConnectorIndex: (state) => {
      return (tspType: string) =>
        state.connectors.findIndex((connector) => connector?.tspType?.toUpperCase() === tspType.toUpperCase());
    },
  },
  actions: {
    setConnectorConfigTestSuccess(payload: boolean) {
      this.test.success = payload;
    },

    async checkCalampConfig(calampRequest: Tsps['CalAmpProxyRequest']) {
      const systemStore = useSystemStore();
      return await API.Tsps.connectCalamp(calampRequest)
        .then((response) => {
          this.setConnectorConfigTestSuccess(true);
          return response.data;
        })
        .catch(() => {
          this.$reset();
          systemStore.addMessage({
            title: 'Error',
            message: `No Connectors for this tenant`,
            type: MessageType.Error,
          });
        });
    },

    checkConnectorConfig() {
      const defaultTspConfig=[geoTabConfig,calAmpConfig,samsaraConfig].map(a => {return {...a}});
      defaultTspConfig.forEach((tsp)=>{
        const existingTsp=this.connectors.findIndex((f) => {
          return f?.tspType === tsp.tspType;
        });
        if (existingTsp>-1) {
          Object.keys(tsp).map((tItem)=>{
            if (tItem!=='tspAttributes') {
              tsp[tItem]=this.connectors[existingTsp][tItem];
            }
          })
          Object.keys(tsp.tspAttributes).map( (item)=> tsp.tspAttributes[item].value= (tsp.tspAttributes[item]?.isSensitive===true?'': this.connectors[existingTsp]?.tspAttributes[item]))
        }
      });
      this.connectors=[...defaultTspConfig];
    },

    async fetchConnectorConfigs() {
      const tenantStore = useTenantStore();
      const systemStore = useSystemStore();
      const tenantId = tenantStore.getSelectedTenantId;
      await API.Tenant.getConnectorConfigs(tenantId)
        .then((response) => {
          this.connectors = response.data;
          this.checkConnectorConfig();
        })
        .catch(() => {
          this.$reset();
          systemStore.addMessage({
            title: 'Error',
            message: `No Connectors for this tenant`,
            type: MessageType.Error,
          });
        });
    },
    async createConnectorConfig(tspType: string) {
      const systemStore = useSystemStore();
      const tenantStore = useTenantStore();
      const connector = this.connectors.find((connector) => connector?.tspType?.toUpperCase() === tspType.toUpperCase());
      if (!connector) {
        systemStore.addMessage({
          title: 'Error',
          message: 'No connector found',
          type: MessageType.Error,
        });
        return;
      }
      try {
        const attributes: Record<string, any> = { ...connector?.tspAttributes };
        Object.keys(attributes).map((k)=>{
          attributes[k]=attributes[k].value;
        });
        attributes.enabled =
          typeof attributes.enabled === 'string' ? attributes.enabled === 'true' : true;
        
        await API.Tenant.createConnectorConfig(
          {
            enabled: true,
            tspAttributes: attributes,
            tspType: connector?.tspType,
            dataLakeAutomation: connector?.dataLakeAutomation
          },
          tenantStore.getSelectedTenantId
        );
        systemStore.addMessage({
          title: 'Success',
          message: `${tspType} created successfully`,
          type: MessageType.Success,
        });
        this.fetchConnectorConfigs();
      } catch (error) {
        console.log(error);
        systemStore.addMessage({
          title: 'Error',
          message: 'Error occurred while creating Geoconfig',
          type: MessageType.Error,
        });
      }
    },

    async updateConnectorConfig(tspType: string) {
      const systemStore = useSystemStore();
      const tenantStore = useTenantStore();
      const connector = this.connectors.find((connector) => connector?.tspType?.toUpperCase() === tspType.toUpperCase());
      const configurationId = this.getConnectorConfurationId(tspType);
      const transformTsp=Object.keys(connector?.tspAttributes).reduce((acc,cv)=>{
        acc[cv]=connector.tspAttributes[cv].value;
        return acc;
      },{});
      const updateRequest={
        configurationId: connector?.configurationId,
        tspType: connector?.tspType,
        tspAttributes: transformTsp,
        enabled: connector?.enabled,
        dataLakeAutomation: connector?.dataLakeAutomation
      };
      await API.Tenant.updateConnectorConfig(
        tenantStore.getSelectedTenantId,
        configurationId!,
        updateRequest
      )
        .then(() => {
          systemStore.addMessage({
            title: 'Success',
            message: `${tspType} config updated successfully`,
            type: MessageType.Success,
          });
          this.fetchConnectorConfigs();
        })
        .catch(() => {
          systemStore.addMessage({
            title: 'Error',
            message: 'Error occured while updating Geoconfig',
            type: MessageType.Error,
          });
        });
        
    },

    async deleteConnectorConfig(tspType: string) {
      const systemStore = useSystemStore();
      const tenantStore = useTenantStore();
      const configurationId = this.getConnectorConfurationId(tspType);
      await API.Tenant.deleteConnectorConfig(
        tenantStore.getSelectedTenantId,
        tspType,
        configurationId!
      )
      .then(() => {
          systemStore.addMessage({
            title: 'Success',
            message: 'Connector deleted successfully',
            type: MessageType.Success,
          });
          this.fetchConnectorConfigs();
          this.setConnectorConfigTestSuccess(false);
          // this.$reset;
        })
      .catch((error) => {
          systemStore.addMessage({
            title: 'Error',
            message: error as string,
            type: MessageType.Error,
          });
        });
      
    },
  },
});
