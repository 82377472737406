<template>
  <div
    class="grid-c-2 bg-blue-900"
    style="min-height: calc(100vh - 83px);"
  >
    <ul class="menu">
      <template
        v-for="item in items"
        :key="item.text"
      >
        <li class="menu-item">
          <component
            :is="item.disabled ? 'span' : 'router-link'"
            :to="item.path"
            active-class="bg-blue-700"
            :class="[
              'text-light u-text-left font-light text-md px-2 py-1 u-flex u-items-center',
              { [$style['route']]: true },
              { [$style['disabled']]: item.disabled !== undefined ? item.disabled : !tenantStore.getSelectedTenantId },
            ]"
          >
            <span
              class="menuGlyph u-flex u-justify-center"
              style="display: inline-block; width: 60px;"
            >
              <SidebarIcons
                :icon="item.text"
              />
            </span>
            {{ item.text }}
          </component>
        </li>
      </template>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import useTenantStore from '@/store/tenant';
import SidebarIcons from '@/components/navigation/SidebarIcons.vue';
import { items } from '@/constants/sideNav';
const tenantStore = useTenantStore();

</script>

<style module lang="scss">
.menuGlyph {
  width: 60px;
  display: inline-block;
  text-align: center;
  font-size: 28px;
}

.disabled {
  color: currentColor;
  cursor: not-allowed !important;
  opacity: 0.25;
  text-decoration: none;
}

.route {
  text-decoration: none;
}

.menu-item::selection {
  background-color: #1e5898 !important;
}

.menu-item {
  a {
    text-decoration: none !important;
  }
}
</style>
