<template>
  <svg
    id="icon_taxonnomy"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="27"
    viewBox="0 0 32 27"
  >
    <path
      id="noun-flow-chart-287793"
      d="M100.546,66.308H99.091V61.4a2.714,2.714,0,0,0-2.909-2.455H87.455V54.036h1.454a1.36,1.36,0,0,0,1.454-1.227V47.9a1.359,1.359,0,0,0-1.454-1.227H83.091A1.36,1.36,0,0,0,81.636,47.9v4.909a1.36,1.36,0,0,0,1.454,1.227h1.454v4.909H75.818A2.714,2.714,0,0,0,72.909,61.4v4.909H71.454A1.359,1.359,0,0,0,70,67.536v4.909a1.36,1.36,0,0,0,1.454,1.227h5.818a1.36,1.36,0,0,0,1.454-1.227V67.536a1.36,1.36,0,0,0-1.454-1.227H75.818V61.4h8.727v4.909H83.091a1.36,1.36,0,0,0-1.454,1.227v4.909a1.36,1.36,0,0,0,1.454,1.227h5.818a1.36,1.36,0,0,0,1.454-1.227V67.536a1.36,1.36,0,0,0-1.454-1.227H87.455V61.4h8.727v4.909H94.727a1.36,1.36,0,0,0-1.454,1.227v4.909a1.36,1.36,0,0,0,1.454,1.227h5.818A1.36,1.36,0,0,0,102,72.445V67.536a1.36,1.36,0,0,0-1.454-1.227Z"
      transform="translate(-70 -46.672)"
      fill="#fff"
    />
  </svg>
</template>