<template>
  <div class="grid grid-gap-3">
    <Header />
    <SidebarMenu />
    <RouterView />
    <ToastMessage />
    <div class="version">
      {{ version }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { RouterView } from 'vue-router';
import SidebarMenu from '@/components/navigation/SidebarMenu.vue';
import Header from '@/components/shared/Header.vue';
import ToastMessage from '@/components/shared/Toast.vue';
import useAuthStore from '@/store/auth';
import setupCoralogix from './vendor/setupCoralogix';

const version = import.meta.env.VITE_VERSION;

const isAuthenticated = computed(() => useAuthStore().isAuthenticated);

onMounted(async () => {
  if (isAuthenticated.value) {
    setupCoralogix();
  }
});
</script>

<style lang="scss">

html {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

.version {
  position: fixed;
  right: 0.5rem;
  bottom: 0.125rem;
  font-size: 9px;
  color: #808080;
}

a {
  &:hover {
    cursor: pointer;
  }
}
</style>
