<template>
  <div class="col-8">
    <div class="level-item input-control pl-6 w-40">
      <DSSelect
        aria-label="Select Tenant"
        aria-labelledby="SelectTenantLabel"
        :v-model="currentType"
        :items="typeItems"
        placeholder="( Select Tenant )"
        datacy="Tenant Selection"
        @change="onChangeSelect"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import DSSelect from '@/components/shared/DSSelect.vue';
import useTenantStore from '@/store/tenant';
import useConnectorConfigStore from '@/store/connectorConfigs';
import useUsersStore from '@/store/users';
import useTaxonomyStore from '@/store/taxonomy';
import useConfigurationStore from '@/store/configuration';
import { useRouter, useRoute } from 'vue-router';

const currentType = ref('risk');
const typeItems = ref<object[]>([]);
const tenantStore = useTenantStore();
const connectorConfigStore = useConnectorConfigStore();
const taxonomyStore = useTaxonomyStore();
const configurationStore = useConfigurationStore();
const usersStore = useUsersStore();
const router = useRouter();
const route = useRoute();

const onChangeSelect = async (event: any) => {
  const selectedTenantArray = tenantStore.tenants.filter(
    (elem: any) => elem.tenantId === event.target.value
  );
  const [selectedTenantObj] = selectedTenantArray;

  tenantStore.setSelectedTenant(selectedTenantObj);

  if (route.name !== 'Home') {
    router.push({ name: 'Home' });
  }

  connectorConfigStore.$reset();
  taxonomyStore.$reset();
  configurationStore.$reset();
  usersStore.$reset();

  if (tenantStore.getSelectedTenantId) {
    await configurationStore.fetchTenantFeatureConfiguration(
      tenantStore.getSelectedTenantId,
      'feature-subscriptions'
    );
  }
};

onMounted(async () => {
  await tenantStore.fetchTenants();
  typeItems.value = tenantStore.getTenantSelectionList;
});
</script>
