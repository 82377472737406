import { type RouteLocationRaw } from 'vue-router';
import { defineStore } from 'pinia';

export enum MessageType {
  Error,
  Warning,
  Success,
}

export type ToastMessage = {
  type: MessageType;
  title: string;
  message: string;
  id: number;
  action?: RouteLocationRaw;
};

type SystemState = {
  messages: ToastMessage[],
  showSidebar: boolean,
  darkMode: boolean,
  hideMainScreenBecauseOfModal: boolean,
};

export default defineStore('system', {
  state: (): SystemState => ({
    messages: [],
    showSidebar: true,
    darkMode: false,
    hideMainScreenBecauseOfModal: false,
  }),

  getters: {
    getSystem: (state: SystemState) => state.messages,
  },

  actions: {
    addMessage(error:any,timeFrame:number=4000) {
      this.messages.push(error);
      setTimeout(() => {
        this.removeMessage();
      }, timeFrame);
    },
    removeMessage() {
      this.messages = [];
    },
  },
});
