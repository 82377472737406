import { type Directive, type DirectiveBinding } from 'vue';

interface HTMLElementWithClickOutside extends HTMLElement {
  __vueClickOutside__?: (event: Event) => void;
}

const handleOutsideClick = (
  event: Event,
  el: HTMLElementWithClickOutside,
  binding: DirectiveBinding<(event: Event) => void>,
) => {
  const target = event.target as Node;
  if (el !== target && !el.contains(target)) {
    binding.value(event);
  } else {
    event.stopPropagation();
  }
};

const clickOutside: Directive<HTMLElementWithClickOutside, (event: Event) => void> = {
  beforeMount(el, binding) {
    const onClick = (event: Event) => handleOutsideClick(event, el, binding);
    el.__vueClickOutside__ = onClick;
    document.addEventListener('click', onClick);
  },
  updated(el, binding) {
    el.__vueClickOutside__ = (event: Event) => handleOutsideClick(event, el, binding);
  },
  unmounted(el) {
    if (el.__vueClickOutside__) {
      document.removeEventListener('click', el.__vueClickOutside__);
      delete el.__vueClickOutside__;
    }
  },
};

export default clickOutside;
