import Client from '../client';
import type { AsssetSyncDto } from '@/api/schema/AssetSync';

export default class AssetSyncApi extends Client {
    constructor(baseUrl = import.meta.env.VITE_ASSET_SYNC_API) {
        super(baseUrl);
        this.baseUrl = import.meta.env.VITE_ASSET_SYNC_API;
      }
      public async getAssets(tenantId: string, query:string) {
        const tenantPath=tenantId?`/${tenantId}`:null;
        return this.instance.get<AsssetSyncDto>(`${tenantPath}/assets${query}`);
      }
      public async uploadActiveVin(data: FormData, tenantId:string) {
        return this.instance.post(
          `/${tenantId}/customer_attributes`,
          data,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Access-Control-Allow-Origin': '*',
            },
          },
        );
      }
}