export const items = [
    {
      disabled: false,
      text: 'Home',
      path: '/home',
    },
    {
      disabled: false,
      text: 'Create',
      path: '/create-tenant',
    },
    {
      text: 'Connections',
      path: '/connections',
    },
    {
      text: 'Taxonomy',
      path: '/taxonomy',
    },
    {
      text: 'Configurations',
      path: '/legacy-configurations',
    },
    {
      text: 'Users',
      class: 'fa-wrapper fas fa-users text-xl px-2',
      path: '/users',
    },
    {
      text: 'Assets',
      class: 'fa-wrapper fas fa-users text-xl px-2',
      path: '/assets',
    },
    {
      disabled: false,
      text: 'Affinity Partners',
      path: '/affinity-partners',
    },
    {
      text: 'Analytics Dashboards',
      path: '/quick-sight',
    },
    {
      disabled: false,
      text: 'Customer Engagement',
      path: '/customer-engagement',
    },
  ];