import type { APIResponse } from '../client';

import Client from '../client';

export interface AnalyticsDashboard {
    embedUrl: string;
}

export default class AnalyticsApi extends Client {
    constructor(baseUrl = import.meta.env.VITE_VISUAL_PROXY_API) {
      super(baseUrl);
      this.baseUrl = baseUrl;
    }
  
    getDashboard(dashboardId: string) {
      return this.instance.get<APIResponse<AnalyticsDashboard>>(`/api/visual-proxy/dashboard/${dashboardId}`);
    }
}