import Client from '../client';

export default class ImpersonateUserApi extends Client {
  constructor(baseUrl = import.meta.env.VITE_AUTH_URL) {
    super(baseUrl);
    this.baseUrl = import.meta.env.VITE_AUTH_URL;
  }

  public async impersonateUser(tenantId: string, userId: string) {
    return this.instance.post(`admin/realms/${tenantId}/users/${userId}/impersonation`);
  }
}