import Client from '../client';
import type { Tsps } from '@/api/schema';

export default class TspsApi extends Client {
  constructor(baseUrl = import.meta.env.VITE_MANAGEMENT_CONFIG_URL) {
    super(baseUrl);
    this.baseUrl = baseUrl;
  }
  public async connectCalamp(request: Tsps['CalAmpProxyRequest']) {
    return this.instance.post<Tsps['CalAmpProxyResponse']>(`/v1/tsps/calamp/connect/services/login`, request);
  }
}
