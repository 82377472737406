export const userRolesDropdown = [
  { value: 'tenant_admin', label: 'Admin' },
  { value: 'tenant_write', label: 'Read/Write' },
  { value: 'tenant_read', label: 'Read Only' },
];

export const userRoles = {
  tenant_write: 'Read/Write',
  tenant_read: 'Read Only',
  tenant_admin: 'Admin',
};
