import Client from '@/api/client';
import type { Configurations } from '@/api/schema';
import { buildQuery } from '@/utils/queryBuilder';

export default class ConfigurationApi extends Client {
  constructor(baseUrl = import.meta.env.VITE_TENANT_CONFIG_URL) {
    super(baseUrl);
    this.baseUrl = baseUrl;
  }

  async getConfiguration(tenantId: string, componentId: string) {
    const query = buildQuery({
      productId:'fleet',
      domainId: 'frontend',
      appId:'fleet-frontend',
      componentId,
      tenantId,   
      inheritanceStrategy: 'NONE',   
    });

    return this
      .instance
      .get<Configurations['ConfigurationDto'][]>(`v1/admin/configurations${query}`);
  }

  async getAllConfiguration(tenantId: string) {
    return this
      .instance
      .get<Configurations['ConfigurationDto'][]>(`v1/admin/tenants/${tenantId}/configurations/merged`);
  }

  async getFeatureConfiguration(tenantId: string, componentId: string) {
    const query = buildQuery({
      componentId
    });

    return this
      .instance
      .get<Configurations['ConfigurationDto'][]>(`v1/admin/tenants/${tenantId}/configurations/merged${query}`);
  }


  async createConfiguration(configuration: Configurations['ConfigurationRequest']) {
    return this.instance.post<Configurations['ConfigurationDto']>('v1/admin/configuration', configuration);
  }

  async updateConfiguration(configurationId:string, configuration: Configurations['ConfigurationRequest']) {
    return this.instance.put<Configurations['ConfigurationDto']>(`v1/admin/configuration/${configurationId}`, configuration);
  }

  async updateFeatureConfiguration(tenantId: string, configurationId:string, configuration: Configurations['ConfigurationRequest']) {
    return this.instance.put<Configurations['ConfigurationDto']>(`v1/admin/tenants/${tenantId}/configurations/${configurationId}`, configuration);
  }

  async createFeatureConfiguration(tenantId: string, configuration: Configurations['ConfigurationRequest']) {
    return this.instance.post<Configurations['ConfigurationDto']>(`v1/admin/tenants/${tenantId}/configurations`, configuration);
  }

  async deleteConfiguration(configurationId: string) {
    return this.instance.delete(`v1/admin/configuration/${configurationId}`);
  }

  async getLegacyConfiguration(tenantId: string) {
    return this.instance.get<Configurations['ConfigurationDto'][]>(`v1/admin/configurations/tenants/${tenantId}`);
  }

  async createLegacyConfiguration(configuration: Configurations['ConfigurationRequest']) {
    return this.instance.post<Configurations['ConfigurationDto']>('v1/admin/configurations', configuration);
  }

  async updateLegacyConfiguration(configurationId:string, configuration: Configurations['ConfigurationRequest']) {
    return this.instance.put<Configurations['ConfigurationDto']>(`v1/admin/configurations/${configurationId}`, configuration);
  }

  async deleteLegacyConfiguration(configurationId: string) {
    return this.instance.delete(`v1/admin/configurations/${configurationId}`);
  }
}
