import { defineStore } from 'pinia';
import useSystemStore, { MessageType } from '@/store/system';
import useTenantStore from '@/store/tenant';
import useTaxonomyStore from '@/store/taxonomy';
import { userRoles } from '@/constants/users';
import { API } from '@/api/plugin';
import { type User } from '@/api/schema';
import { buildQuery } from '@/utils/queryBuilder';

type GetUsersResponse = {
  totalPages: number;
  totalElements: number;
  size: number;
  content: User['UserDto'][];
  number: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  pageable: {
    offset: number;
    sort: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    unpaged: boolean;
  };
  first: boolean;
  last: boolean;
  numberOfElements: number;
  empty: boolean;
};
type UserState = {
  users: GetUsersResponse;
  allUsers: User['UserDto'][],
  selectedUser: User['UserDto'];
};

export default defineStore('users', {
  state: (): UserState => ({
    users: {
      totalPages: 0,
      totalElements: 0,
      size: 0,
      content: [],
      number: 0,
      sort: {
        empty: true,
        sorted: true,
        unsorted: true,
      },
      pageable: {
        offset: 0,
        sort: {
          empty: true,
          sorted: true,
          unsorted: true,
        },
        pageNumber: 0,
        pageSize: 0,
        paged: true,
        unpaged: true,
      },
      first: true,
      last: true,
      numberOfElements: 0,
      empty: true,
    },
    allUsers: [],
    selectedUser: {
      userid: '',
      username: '',
      firstname: '',
      lastname: '',
      email: '',
      assignedAccessLevels: [
        {
          id: '',
          name: 'tenant_write',
          description: 'uptake-perm',
          composite: false,
          clientRole: false,
          containerId: '',
        },
      ],
      userPermissions: [],
    },
  }),

  getters: {
    getSelectedUserName: (state) => {
      if (state.selectedUser && state.selectedUser.username) {
        return state.selectedUser.username;
      }
      return '';
    },
    getSelectedUserId: (state) => {
      if (state.selectedUser && state.selectedUser.userid) {
        return state.selectedUser.userid;
      }
      return '';
    },
    getSelectedUserPermissions: (state) => {
      if (state.selectedUser && state.selectedUser.userPermissions) {
        return state.selectedUser.userPermissions;
      }
      return [];
    },
    getSelectedUserPermissionsTaxonomyPath: (state) => {
      if (state.selectedUser && state.selectedUser.userPermissions) {
        return state.selectedUser.userPermissions.map((taxonomy) => {
          return taxonomy?.taxonomyPath?.map((path) => {
            return path.level + '=' + path.item;
          });
        });
      }
      return [];
    },
    getSelectedUserAccessLevels: (state) => {
      if (state.selectedUser && state.selectedUser.assignedAccessLevels) {
        return state.selectedUser.assignedAccessLevels;
      }
      return [];
    },
    getUserAssignedAccessLevelsById: (state) => (id: string) => {
      const user = state.users.content.find((user) => user?.userid === id);
      if (user?.assignedAccessLevels?.[0]?.name) {
        return (userRoles as any)[user.assignedAccessLevels[0].name];
      } else {
        return '';
      }
    },
  },

  actions: {
    setUsers(payload: GetUsersResponse) {
      this.users = payload;
    },

    setSelectedUser(payload: User['UserDto']) {
      this.selectedUser = payload;
    },
    async fetchAllUsers() {
      const tenantStore = useTenantStore();

      const query = buildQuery({
        responseFormat: 'list',
        enabledOnly: true
      });

      const response = await API.Users.getUsers(tenantStore.getSelectedTenantId, query);
      this.allUsers = response.data;
      return response.data;
    },
    async fetchUsers(page?: number, size?: number ) {
      const tenantStore = useTenantStore();
      const systemStore = useSystemStore();
      const query = buildQuery({
        responseFormat: 'page',
        page: page,
        size: size,
        enabledOnly: true
      });
      
      await API.Users.getUsers(tenantStore.getSelectedTenantId, query)
        .then((response) => {
          this.setUsers(JSON.parse(JSON.stringify(response.data)));
        })
        .catch((error) => {
          systemStore.addMessage({
            title: 'Error',
            message: error as string,
            type: MessageType.Error,
          });
        });
    },

    userPatchObject(resetPassword: boolean, permissions?: User['PermissionDto'] | 'ALL' | 'NONE') {
      if (!this.getSelectedUserAccessLevels[0]?.name) {
        return;
      }
      const { getTenantUserAccesLevelByName } = useTenantStore();
      const taxonomyStore = useTaxonomyStore();
      const userPatchObject = {
        firstname: this.selectedUser?.firstname,
        lastname: this.selectedUser?.lastname,
        email: this.selectedUser?.email,
        resetpassword: resetPassword,
        enabled: this.selectedUser?.enabled,
        ...(this.selectedUser?.assignedAccessLevels && {
          assignedAccessLevels: [
            {
              id: getTenantUserAccesLevelByName(this.getSelectedUserAccessLevels[0]?.name)?.id,
              name: this.selectedUser?.assignedAccessLevels[0].name,
              description: 'uptake-perm',
              composite: false,
              clientRole: false,
              containerId: getTenantUserAccesLevelByName(this.getSelectedUserAccessLevels[0]?.name)
                ?.containerId,
            },
          ],
        }),
      };
      if (permissions !== 'ALL' && permissions !== 'NONE' && permissions !== undefined) {
        (userPatchObject as any)['userPermissions'] = permissions;
      }
      if (permissions === 'ALL') {
        (userPatchObject as any)['userPermissions'] = [
          {
            type: 'TAXONOMY',
            taxonomy: taxonomyStore.getInUseTaxonomy,
            taxonomyPath: [],
          },
        ];
      }
      if (permissions === 'NONE') {
        (userPatchObject as any)['userPermissions'] = [];
      }
      return userPatchObject;
    },

    async createUser() {
      const systemStore = useSystemStore();
      const { getSelectedTenantId, getTenantUserAccesLevelByName } = useTenantStore();
      if (!this.getSelectedUserAccessLevels[0]?.name) {
        return;
      }
      await API.Users.createUser(getSelectedTenantId, {
        username: this.getSelectedUserName,
        ...(this.selectedUser?.firstname &&
          this.selectedUser?.lastname && { firstname: this.selectedUser?.firstname }),
        ...(this.selectedUser?.firstname &&
          this.selectedUser?.lastname && { lastname: this.selectedUser?.lastname }),
        ...(this.selectedUser?.email && { email: this.selectedUser?.email }),
        assignedRoles: [],
        ...(this.selectedUser?.assignedAccessLevels && {
          assignedAccessLevels: [
            {
              id: getTenantUserAccesLevelByName(this.getSelectedUserAccessLevels[0]?.name)?.id,
              name: this.selectedUser?.assignedAccessLevels[0].name,
              description: 'uptake-perm',
              composite: false,
              clientRole: false,
              containerId: getTenantUserAccesLevelByName(this.getSelectedUserAccessLevels[0]?.name)
                ?.containerId,
            },
          ],
        }),
      } as User['CreateUserRequest'])
        .then(() => {
          systemStore.addMessage({
            title: 'Success',
            message: 'User added successfully',
            type: MessageType.Success,
          });
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 409) {
            systemStore.addMessage({
              title: 'Error',
              message: 'The username or email entered already exists',
              type: MessageType.Error,
            });
          } else {
            systemStore.addMessage({
              title: 'Error',
              message: error as string,
              type: MessageType.Error,
            });
          }
        });
    },

    async updateUser() {
      const systemStore = useSystemStore();
      const tenantStore = useTenantStore();
      await API.Users.updateUser(
        tenantStore.getSelectedTenantId,
        this.getSelectedUserId,
        this.userPatchObject(false, this.selectedUser?.userPermissions!)
      )
        .then(() => {
          systemStore.addMessage({
            title: 'Success',
            message: 'User updated successfully',
            type: MessageType.Success,
          });
        })
        .catch((error) => {
          systemStore.addMessage({
            title: 'Error',
            message: error as string,
            type: MessageType.Error,
          });
        });
    },

    async searchUsers(searchString: string, query: string) {
      const systemStore = useSystemStore();
      const tenantStore = useTenantStore();

      await API.Users.searchUsers(tenantStore.getSelectedTenantId, {
        username: searchString
      }, query)
        .then((response) => {
          this.setUsers(JSON.parse(JSON.stringify(response.data)));
        })
        .catch((error) => {
          systemStore.addMessage({
            title: 'Error',
            message: error as string,
            type: MessageType.Error,
          });
        });
    },

    async updateUserPermissions(permissions?: User['PermissionDto'] | 'ALL' | 'NONE') {
      const systemStore = useSystemStore();
      const tenantStore = useTenantStore();
      await API.Users.updateUser(
        tenantStore.getSelectedTenantId,
        this.getSelectedUserId,
        this.userPatchObject(false, permissions)
      )
        .then(() => {
          this.fetchUsers(this.users.number);
          systemStore.addMessage({
            title: 'Success',
            message: 'User updated successfully',
            type: MessageType.Success,
          });
        })
        .catch((error) => {
          systemStore.addMessage({
            title: 'Error',
            message: error as string,
            type: MessageType.Error,
          });
        });
    },

    async resetPassword() {
      const systemStore = useSystemStore();
      const tenantStore = useTenantStore();
      await API.Users.updateUser(
        tenantStore.getSelectedTenantId,
        this.getSelectedUserId,
        this.userPatchObject(true)
      )
        .then(() => {
          this.fetchUsers(this.users.number);
          systemStore.addMessage({
            title: 'Success',
            message: 'User password reset successfully',
            type: MessageType.Success,
          });
        })
        .catch((error) => {
          systemStore.addMessage({
            title: 'Error',
            message: error as string,
            type: MessageType.Error,
          });
        });
    },

    async deleteUser() {
      const systemStore = useSystemStore();
      const tenantStore = useTenantStore();
      await API.Users.deleteUser(tenantStore.getSelectedTenantId, this.getSelectedUserId)
        .then(() => {
          this.fetchUsers(this.users.number);
          systemStore.addMessage({
            title: 'Success',
            message: 'User deleted successfully',
            type: MessageType.Success,
          });
        })
        .catch((error) => {
          systemStore.addMessage({
            title: 'Error',
            message: error as string,
            type: MessageType.Error,
          });
        });
    }
  },
});