<template>
  <span
    :class="$style.dsselect"
    :data-cy="datacy + '_Container'"
  >
    <span
      :class="{
        [$style['dsselect__spinner']]: loading,
      }"
    >
      <select
        id="selectItem"
        v-model="selectValue"
        :data-cy="datacy"
        :class="[
          { [$style['dsselect__field']]: true },
          { [$style['dsselect__field--loading']]: loading },
          { [$style['dsselect__field--expanded']]: expanded },
        ]"
        class="p-0 pl-2 bg-gray-000"
        style="width: 400px; height: 50px; font-size: 18px;"
        :disabled="disabled"
        :v-model="modelValue"
        @change="$emit('update:modelValue', $event.target as HTMLInputElement)"
      >
        <option value="">{{ placeholder }}</option>
        <option
          v-for="(item, index) in items"
          :key="index"
          :data-cy="item.datacy"
          :value="item.value"
        >
          {{ item.label }}
        </option>
      </select>
    </span>
  </span>
</template>

<script lang="ts">
import { defineComponent,type Prop } from 'vue';

export interface SelectItem {
  label: string;
  value: string;
  key?: string;
  selected?: string;
  datacy?: string;
}

export default defineComponent({
  name: 'DSSelect',
  props: {
    modelValue: String,
    items: {} as Prop<[SelectItem]>,
    placeholder: {
      type: String,
      required: false,
      default: 'Select option',
    },
    id: {
      type: String,
      required: false,
    },
    selectId: {
      type: String,
      required: false,
      default: 'UAVSelectAction',
    },

    datacy: {
      type: String,
      required: false,
      default: 'Dropdown',
    },
    label: {
      type: String,
      required: false,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectValue: '',
    };
  },
});
</script>

<style module lang="scss" scoped>
.dsselect {
  &__label {
    color: ds-get-color(pewter-grey);
    display: block;
    padding-bottom: 4px;

    &--disabled {
      color: ds-get-color(platinum-grey);
    }
  }

  &__spinner {
    &:after {
      margin-top: 0;
      margin-right: -20px;
      right: 1.6em;
      top: 0.3em;
    }

    &:after {
      animation: spinner 0.5s infinite linear;
      border: 2px solid ds-get-color(concrete-grey);
      border-radius: 290486px;
      border-right-color: transparent;
      border-top-color: transparent;
      content: "";
      display: inline-block;
      height: 1em;
      position: relative;
      width: 1em;
    }
  }

  &__field {
    appearance: none;
    background: #ffffff 0% 0% no-repeat padding-box;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='6'><path fill='%230D46BF' d='M12 0L6 6 0 0z'/></svg>");
    background-position: calc(100% - 1rem) 50%;
    border: 1px solid #dee2e6;
    border-radius: 3px;
    padding-left: 5px;
    padding-bottom: 2px;
    width: calc(100% - 1rem);
    cursor: pointer;

    &:disabled {
      background-color: ds-get-color(foggy-grey);
      border-color: ds-get-color(platinum-grey);
      color: ds-get-color(stainless-grey);
      cursor: not-allowed;
    }

    &--loading {
      background-size: 0 0;
    }

    &--expanded {
      width: 100%;
    }
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
