import type { KeycloakConfig } from 'keycloak-js';

const authURL = import.meta.env.VITE_AUTH_URL as string;
const clientId = import.meta.env.VITE_CLIENT_ID as string;
const realm = import.meta.env.VITE_REALM_NAME as string;

const keycloakClientOptions: KeycloakConfig = {
  realm,
  url: authURL,
  clientId,
};

export default keycloakClientOptions;
