import { defineStore } from 'pinia';
import { API } from '@/api/plugin';
import { type Tenant } from '@/api/schema';
import useSystemStore, { MessageType } from '@/store/system';
import useUsersStore from '@/store/users';
import { type SelectItem } from '@/components/shared/DSSelect.vue';
import { KeycloakEventType } from '@/constants/tenants';
import { formatDateTimeWithPeriod } from '@/utils/timeUtils';
import { buildQuery } from '@/utils/queryBuilder';

declare type TenantState = {
  tenants: Tenant['TenantDto'][];
  affinityPartner: any;
  childTenant: any;
  selectedTenant: Tenant['TenantDto'];
  loginHistory: Tenant['UserEventDto'][];
  tenantUserRoles: Tenant['RoleRepresentation'];
  tenantUserAccessLevels: Tenant['RoleRepresentation'];
  tenantExternalUserAccess: any;
};

export default defineStore('tenant', {
  state: (): TenantState => ({
    tenants: [],
    affinityPartner: {
      tenantId: '',
      displayName: '',
      salesforceId: undefined,
      defaultUserPermissions: {
        defaultGlobalPermissions: 'ALL',
      },
      childTenantLimit: 0,
      partner: false,
    },
    childTenant: {
      tenantId: '',
      displayName: '',
      parentTenantId: '',
      salesforceId: undefined,
      defaultUserPermissions: {
        defaultGlobalPermissions: 'ALL',
      },
      partner: false,
    },
    selectedTenant: undefined,
    loginHistory: [],
    tenantUserRoles: undefined,
    tenantUserAccessLevels: undefined,
    tenantExternalUserAccess: undefined,
  }),

  getters: {
    getTenantById: (state) =>
      (tenantId: string): Tenant['TenantDto'] => {
        let tenant: Tenant['TenantDto'] = undefined;
        if (state.tenants) {
          state.tenants.map((t) => {
            if (t !== undefined && t.tenantId === tenantId) {
              tenant = t;
            }
          });
        }
        return tenant;
      },
    getSalesforceIdbyTenantId: (state) =>
      (tenantId: string): string => {
        let salesforceId = '';
        if (state.tenants) {
          state.tenants.map((tenant) => {
            if (tenant !== undefined && tenant.tenantId === tenantId) {
              salesforceId = tenant.salesforceId;
            }
          });
        }
        return salesforceId;
      },
    getPartnerTenants: (state): Tenant['TenantDto'][] => {
      const partnerTenants: Tenant['TenantDto'][] = [];
      if (state.tenants) {
        state.tenants.map((tenant) => {
          if (tenant !== undefined && tenant.partner) {
            partnerTenants.push(tenant);
          }
        });
      }
      return partnerTenants;
    },
    getChildTenantsByParentId: (state) =>
      (parentId: string): Tenant['TenantDto'][] => {
        const childTenants: Tenant['TenantDto'][] = [];
        if (state.tenants) {
          state.tenants.map((tenant) => {
            if (tenant !== undefined && tenant.parentTenantId === parentId) {
              childTenants.push(tenant);
            }
          });
        }
        return childTenants;
      },
    getTenantIds: (state): string[] => {
      const tenantIds: string[] = [];
      if (state.tenants) {
        state.tenants.map((tenant) => {
          if (tenant !== undefined) {
            for (const [key, value] of Object.entries(tenant)) {
              if (key === 'tenantId' && typeof value === 'string') {
                tenantIds.push(value);
              }
            }
          }
        });
      }
      return tenantIds;
    },
    getTenantSelectionList: (state): SelectItem[] => {
      const typeItems: SelectItem[] = [];
      if (!state.tenants) return typeItems;
      return state.tenants
        .filter((tenant: Tenant['TenantDto']) => tenant && !tenant.partner)
        .map((tenant: Tenant['TenantDto']) => ({
          label: `${tenant!.parentTenantId ? `[${tenant!.parentTenantId}]` : ''} ${
            tenant!.tenantId
          }`,
          value: tenant!.tenantId,
        }))
        .sort((a, b) => a.value.localeCompare(b.value));
    },
    getSelectedTenantId: (state) => {
      if (state.selectedTenant && state.selectedTenant.tenantId) {
        return state.selectedTenant.tenantId;
      }
      return '';
    },
    getSelectedTenantDisplayName: (state) => {
      if (state.selectedTenant && state.selectedTenant.displayName) {
        return state.selectedTenant.displayName;
      }
    },
    getSelectedTenanttSalesforceId: (state) => {
      if (state.selectedTenant && state.selectedTenant.salesforceId) {
        return state.selectedTenant.salesforceId;
      }
    },
    getSelectedTenanttUserPermissions: (state) => {
      if (
        state.selectedTenant &&
        state.selectedTenant.defaultUserPermissions?.defaultGlobalPermissions
      ) {
        return state.selectedTenant.defaultUserPermissions.defaultGlobalPermissions;
      }
    },
    getLastLoginByUserId: (state) => (userId: string): string => {
        const loginHistory: Tenant['UserEventDto'][] = [];
        // get the loginHistory based on the userId
        if (state.loginHistory) {
          state.loginHistory.map((event) => {
            if (event?.userid === userId) {
              loginHistory.push(event);
            }
          });
        }
        if (loginHistory.length === 0) return '';
        // only return the latest time in the array
        const latestLogin: any = loginHistory.reduce((prev: any, current: any) =>
          prev.time > current.time ? prev : current
        );
        return formatDateTimeWithPeriod(latestLogin.time);
    },
    getLoginHistory(state): any {
      const res = [] as any;
      state.loginHistory
        .sort((a:any, b:any) => b.time - a.time)
        .forEach((event:any) => {
          const user:any = useUsersStore().users.content.find((y:any) => y.userid === event.userid);
          // If the user is not found in the users list, it doesn't include it in the results
          if (!user) return;
          const { lastname, firstname, email } = user;
          res.push({
            ...event,
            firstname,
            lastname,
            email,
          });
        });
      return res;
    },
    getTenantUserAccesLevelByName: (state) => (name: string): Tenant['RoleRepresentation'] => {
      if (state.tenantUserAccessLevels) {
        const level = (state.tenantUserAccessLevels as any)?.find((x:any) => x.name === name);
        if (level) {
          return level;
        }
      }
      return undefined;
    }
  },

  actions: {
    setAffinityPartner(payload: any) {
      this.affinityPartner = payload;
    },
    setChildTenant(payload: Tenant['TenantDto']) {
      this.childTenant = payload;
    },

    setSelectedTenant(payload: Tenant['TenantDto']) {
      this.selectedTenant = payload;
    },

    setTenants(payload: Tenant['TenantDto'][]) {
      this.tenants = payload;
    },
    setUpdatedTenant(payload: Tenant['TenantDto']) {
      this.tenants.map((tenant, index) => {
        if (tenant && tenant?.tenantId === payload?.tenantId) {
          this.tenants[index] = payload;
        }
      });
    },
    setSelectedTenantDefaultUserPermissions(payload: Tenant['TenantDefaultUserPermissionsDto']) {
      if (this.selectedTenant) {
        this.selectedTenant.defaultUserPermissions = payload;
      }
    },
    async fetchTenants() {
      const systemStore = useSystemStore();
      await API.Tenant.getTenants()
        .then((response) => {
          this.setTenants([...response.data]);
        })
        .catch((error) => {
          systemStore.addMessage({
            title: 'Error',
            message: error as string,
            type: MessageType.Error,
          });
        });
    },
    async createTenant(tenantId: string, createTenantRequest: Tenant['CreateTenantRequest']) {
      const systemStore = useSystemStore();
      await API.Tenant.createTenant(tenantId, createTenantRequest)
        .then((response) => {
          this.tenants = [...this.tenants, ...[response.data]];
          systemStore.addMessage({
            title: 'Success',
            message: 'Tenant created successfully',
            type: MessageType.Success,
          });
        })
        .catch((error) => {
          systemStore.addMessage({
            title: 'Error',
            message: error as string,
            type: MessageType.Error,
          });
        });
    },
    async createAffinityPartner() {
      const systemStore = useSystemStore();
      try {
        if (!this.affinityPartner.tenantId) {
          throw new Error('Affinity Partner tenantId is required');
        }
        const response = await API.Tenant.createTenant(this.affinityPartner.tenantId, {
          displayName: this.affinityPartner.displayName,
          salesforceId: this.affinityPartner.salesforceId,
          defaultUserPermissions: {
            defaultGlobalPermissions: 'ALL',
          },
          parentTenantId: this.affinityPartner.parentTenantId,
          childTenantLimit: this.affinityPartner.childTenantLimit,
          partner: true,
        });
        this.affinityPartner = response.data;
        systemStore.addMessage({
          title: 'Success',
          message: 'Affinity Partner created successfully',
          type: MessageType.Success,
        });
      } catch (error) {
        systemStore.addMessage({
          title: 'Error',
          message: error as string,
          type: MessageType.Error,
        });
      }
    },

    async createChildTenant() {
      const systemStore = useSystemStore();
      try {
        if (!this.childTenant.tenantId) {
          throw new Error('Child Tenant tenantId is required');
        }
        await API.Tenant.createTenant(`${this.childTenant.tenantId}`, {
          displayName: this.childTenant.displayName,
          salesforceId: this.childTenant.salesforceId,
          defaultUserPermissions: this.childTenant.defaultUserPermissions,
          parentTenantId: this.affinityPartner.tenantId,
          partner: false,
        });
        systemStore.addMessage({
          title: 'Success',
          message: 'Child Tenant created successfully',
          type: MessageType.Success,
        });
      } catch (error) {
        systemStore.addMessage({
          title: 'Error',
          message: error as string,
          type: MessageType.Error,
        });
      }
    },
    async updateAffinityPartner() {
      const systemStore = useSystemStore();
      try {
        if (!this.affinityPartner.tenantId) {
          throw new Error('Affinity Partner tenantId is required');
        }
        const response = await API.Tenant.updateTenant(this.affinityPartner.tenantId, {
          childTenantLimit: this.affinityPartner.childTenantLimit,
        });
        this.affinityPartner = response.data;
        this.setUpdatedTenant(response.data);
        systemStore.addMessage({
          title: 'Success',
          message: 'Affinity Partner created successfully',
          type: MessageType.Success,
        });
      } catch (error) {
        systemStore.addMessage({
          title: 'Error',
          message: error as string,
          type: MessageType.Error,
        });
      }
    },
    async enableDisableTenant(tenantId: string, value: boolean) {
      const systemStore = useSystemStore();
      try {
        const response = await API.Tenant.updateTenant(tenantId, {
          enabled: value,
        });
        this.setUpdatedTenant(response.data);
        systemStore.addMessage({
          title: 'Success',
          message: 'Configuration updated successfully',
          type: MessageType.Success,
        });
      } catch (error) {
        systemStore.addMessage({
          title: 'Error',
          message: error as string,
          type: MessageType.Error,
        });
      }
    },
    async updateTenant(tenantId: string, updateTenantRequest: Tenant['UpdateTenantRequest']) {
      const systemStore = useSystemStore();
      await API.Tenant.updateTenant(tenantId, updateTenantRequest)
        .then((response) => {
          this.setUpdatedTenant(response.data);
          this.fetchTenants();
          systemStore.addMessage({
            title: 'Success',
            message: 'Tenant updated successfully',
            type: MessageType.Success,
          });
        })
        .catch((error) => {
          systemStore.addMessage({
            title: 'Error',
            message: error as string,
            type: MessageType.Error,
          });
        });
    },
    async fetchLoginHistory(tenantId: string) {
      const systemStore = useSystemStore();
      const query = buildQuery({
        eventType: KeycloakEventType.LOGIN,
      });
  
      await API.Tenant.getLoginHistory(tenantId, query)
        .then((response) => {
          this.loginHistory = response.data;
        })
        .catch((error) => {
          systemStore.addMessage({
            title: 'Error',
            message: error as string,
            type: MessageType.Error,
          });
        });
    },
    async fetchTenantUserRoles() {
      const systemStore = useSystemStore();
      if (!this.selectedTenant?.tenantId) return;
      await API.Tenant.getTenantUserRoles(this.selectedTenant?.tenantId)
        .then((response) => {
          this.tenantUserRoles = response.data;
        })
        .catch((error) => {
          systemStore.addMessage({
            title: 'Error',
            message: error as string,
            type: MessageType.Error,
          });
        });
    },
    async fetchTenantUserAccessLevels() {
      const systemStore = useSystemStore();
      if (!this.selectedTenant?.tenantId) return;
      await API.Tenant.getTenantUserAccessLevels(this.selectedTenant?.tenantId)
        .then((response) => {
          this.tenantUserAccessLevels = response.data;
        })
        .catch((error) => {
          systemStore.addMessage({
            title: 'Error',
            message: error as string,
            type: MessageType.Error,
          });
        });
    },
    async fetchTenantExternalUserAccess () {
      const systemStore = useSystemStore();
      if (!this.selectedTenant?.tenantId) return;
      await API.Tenant.getTenant(this.selectedTenant?.tenantId)
        .then((response) => {
          this.tenantExternalUserAccess = response.data;
        })
        .catch((error) => {
          systemStore.addMessage({
            title: 'Error',
            message: error as string,
            type: MessageType.Error,
          });
        });
      }
  },
});
