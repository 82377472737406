import queryString from 'query-string';

type QueryKeys =
  | 'productId'
  | 'domainId'
  | 'appId'
  | 'componentId'
  | 'page'
  | 'size'
  | 'page_size'
  | 'responseFormat'
  | 'sort'
  | 'sortCriteria'
  | 'sortDirection'
  | 'eventType'
  | 'enabledOnly'
  | 'tenantId'
  | 'inheritanceStrategy';

type QueryParams = {
  [key in QueryKeys]?: string | number | boolean | string[];
};

export function buildQuery(options: QueryParams): string {
  if (options.sortCriteria && options.sortDirection) {
    options.sort = `${options.sortCriteria},${options.sortDirection}`;
    delete options.sortCriteria;
    delete options.sortDirection;
  }
  const query = queryString.stringify(options);
  return query ? `?${query}` : '';
}