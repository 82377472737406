import { type RouteRecordRaw, createWebHistory, createRouter } from 'vue-router';
import { guard, disabledGuard } from './helpers';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Root',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/connections',
    name: 'Connections',
    beforeEnter: guard,
    component: () => import('../views/Connections.vue'),
  },
  {
    path: '/create-tenant',
    name: 'Tenant',
    component: () => import('../views/Create.vue'),
  },
  {
    path: '/quick-sight',
    name: 'Analytics Dashboards',
    beforeEnter: guard,
    component: () => import('../views/AnalyticsDashboards.vue'),
  },
  {
    path: '/taxonomy',
    name: 'Taxonomy',
    beforeEnter: guard,
    component: () => import('../views/Taxonomy.vue'),
  },
  {
    path: '/configurations',
    name: 'Configurations',
    beforeEnter: guard,
    component: () => import('../views/Configurations.vue'),
  },
  {
    path: '/legacy-configurations',
    name: 'Legacy Configurations',
    beforeEnter: guard,
    component: () => import('../views/LegacyConfigurations.vue'),
  },
  {
    path: '/users',
    name: 'Users',
    beforeEnter: guard,
    component: () => import('../views/Users.vue'),
  },
  {
    path: '/assets',
    name: 'Assets',
    beforeEnter: guard,
    component: () => import('../views/Assets.vue'),
  },
  {
    path: '/affinity-partners',
    name: 'Affinity Partners',
    component: () => import('../views/AffinityPartners/index.vue'),
  },
  {
    path: '/affinity-partners/add-partner',
    name: 'Add Partner',
    component: () => import('@/views/AffinityPartners/Partner.vue'),
  },
  {
    path: '/affinity-partners/add-tenant',
    name: 'Add Tenant',
    component: () => import('@/views/AffinityPartners/Tenant.vue'),
  },
  {
    path: '/customer-engagement',
    name: 'Customer Engagement',
    component: () => import('@/views/CustomerEngagement.vue'),
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
