export const formatDateTimeWithPeriod = (value: number) => {
  const [, tzName]: any = /.*\s(.+)/.exec(new Date(value).toLocaleDateString(undefined, { timeZoneName: 'short' }));
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dateTimeFormat = new Intl.DateTimeFormat('default', {
    day: '2-digit',
    month: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: timeZone,
  });

  const [
    { value: month },
    { value: day },
    { value: hour },
    { value: minute },
    { value: dayPeriod },
  ] = dateTimeFormat.formatToParts(value).filter((x) => x.type !== 'literal');

  const formattedDate = `${month}/${day} @ ${hour}:${minute}${dayPeriod} ${tzName}`;

  return formattedDate;
};

export const formatDate=(dateObj:Date,formatSymbol:string='')=>{
  const month   = dateObj.getUTCMonth() + 1; // months from 1-12
  const day     = dateObj.getUTCDate();
  const year    = dateObj.getUTCFullYear();
  const pMonth        = month.toString().padStart(2,"0");
const pDay          = day.toString().padStart(2,"0");
return `${year}${formatSymbol}${pMonth}${formatSymbol}${pDay}`;
};