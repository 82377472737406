<template>
  <div class="grid-c-12 bg-blue-100">
    <div class="row no-space">
      <div class="col-2 pt-1 u-text-right">
        <img
          alt="uptake logo"
          style="width: 200px; margin-left: auto;"
          src="../../assets/img/Uptake.png"
        ><b>{{ t('shared.management') }}</b>
      </div>
      <Suspense>
        <TenantSelection v-if="authStore.isAuthenticated" />
      </Suspense>
      <div class="col-2 u-text-right pr-2 pt-1 ml-auto">
        <DSButton
          label="Logout"
          size="medium"
          @keyup="logout"
          @click="logout"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import TenantSelection from '@/components/tenant/TenantSelection.vue';
import useAuthStore from '@/store/auth';
import { keycloak } from '@/api/auth';
import { useI18n } from 'vue-i18n';

const authStore = useAuthStore();
const { t } = useI18n();

function logout() {
  keycloak.logout();
}

</script>
