import { type Plugin } from 'vue';
import TenantApi from './routes/tenant';
import TaxonomyApi from './routes/taxonomy';
import ConfigurationApi from './routes/configuration';
import UsersApi from './routes/users';
import AuthApi from './routes/auth';
import MetricsApi from './routes/metrics';
import TspsApi from './routes/tsps';
import ImpersonateUserApi from './routes/impersonate';
import AnalyticsApi from './routes/AnalyticsApi';
import AssetSyncApi from "./routes/assetSync";
const Tenant = new TenantApi();
const Taxonomy = new TaxonomyApi();
const Configuration = new ConfigurationApi();
const Users = new UsersApi();
const Auth = new AuthApi();
const Metrics = new MetricsApi();
const Tsps = new TspsApi();
const Impersonate = new ImpersonateUserApi();
const Analytics = new AnalyticsApi();
const AssetSync=new AssetSyncApi();

export const API = {
  Taxonomy, Tenant, Configuration, Users, Auth, Metrics, Tsps, Impersonate, Analytics, AssetSync
};

const apiClient: Plugin = {
  install: (app) => {
    app.config.globalProperties.$api = API;
    app.provide('api', API);
  },
};

export default apiClient;

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $api: typeof API;
  }
}
