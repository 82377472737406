import type { User } from '@/api/schema';
import Client from '../client';

export default class UsersApi extends Client {
  constructor(baseUrl = import.meta.env.VITE_USER_URL) {
    super(baseUrl);
    this.baseUrl = import.meta.env.VITE_USER_URL;
  }

  public async getUsers(tenantId: string, query:string) {
    return this.instance.get<User['UserDto'][]>(`/v2/tenants/${tenantId}/users${query}`);
  }
  
  public async createUser(tenantId: string, userDetails: User['CreateUserRequest']) {
    return this.instance.post<User['CreateUserRequest']>(`/v1/tenants/${tenantId}/users`, userDetails);
  }

  public async updateUser(tenantId: string, userId: string, userDetails: User['UpdateUserRequest']) {
    return this.instance.patch<User['UpdateUserRequest']>(`/v1/tenants/${tenantId}/users/id/${userId}`, userDetails);
  }

  public async deleteUser(tenantId: string, userId: string) {
    return this.instance.delete(`/v1/tenants/${tenantId}/users/id/${userId}`);
  }

  public async searchUsers(tenantId: string, searchObj: User['KeycloakUserSearch'], query?: string) {
    return this.instance.post<User['PageUserDto'][]>(`/v1/tenants/${tenantId}/users/search${query ? query: ''}`, searchObj);
  }
}
