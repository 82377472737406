<template>
  <component
    :is="iconComponents[props.icon]"
    color="white"
    :fill="iconComponents[props.icon].__name === 'AssetsIcon' ? 'white' : 'none'"
    :style="{
      width: '30px'
    }"
  />
</template>
<script lang="ts" setup>
import Taxonomy from '@/components/icons/Taxonomy.vue';
import { AssetsIcon } from '@uptake/uptake-component-library';

import {
  PlugIcon,
  SettingsIcon,
  PlusIcon,
  HomeIcon,
  ChartPieIcon,
  UsersIcon,
  WindowIcon
} from '@uptake/uptake-component-library';


const props = defineProps({
  class: {
    type: String,
    default: "",
  },
  icon: {
    type: String,
    default: "",
  },
});

const iconComponents: any = {
  Home: HomeIcon,
  'Affinity Partners': WindowIcon,
  Configurations: SettingsIcon,
  Connections: PlugIcon,
  Create: PlusIcon,
  'Analytics Dashboards': ChartPieIcon,
  Taxonomy,
  Users: UsersIcon,
  'Assets':AssetsIcon,
  'Customer Engagement': ChartPieIcon,
};
</script>
